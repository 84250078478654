<template>
  <div style="width: 100vw; height: 100vh" v-loading="loading">
    <div class="satisfaction" v-if="!isSaved && !loading">
      <div class="satisfaction-head">
        <img src="@/assets/images/logo.png" height="40" />
      </div>
      <div class="satisfaction-body">
        <div class="message">
          <div>{{ formInfo.candidateName }}，您好！</div>
          <div>您于{{ formInfo.interviewTime | interviewTime }}参加了{{ formInfo.positionName }}的面试， 请对这次面试进行评价吧！</div>
        </div>

        <div class="split-line"></div>
        <el-form :model="form" ref="form" label-position="top">
          <div class="section" v-for="(mod, index) in modules" :key="index">
            <div class="title">{{ mod.name }}</div>
            <div class="desc">{{ mod.description }}</div>
            <div class="field-warp">
              <template v-for="(question, $index) in mod.questionDTOList || []">
                <el-form-item :key="index + '-' + $index" :prop="getPropName(index, $index, question)" :rules="getRules(question)" :class="{ 'form-rate': question.type === 1 }">
                  <template slot="label">
                    {{ question.title }}
                    <template v-if="question.type === 2">（单选）</template>
                    <template v-if="question.type === 3">（多选）</template>
                  </template>
                  <template v-if="question.type === 1">
                    <el-rate v-model="form.commentData[getQuestionIndex(index, $index)].replyScore"></el-rate>
                  </template>
                  <template v-if="question.type === 2">
                    <el-radio-group v-model="form.commentData[getQuestionIndex(index, $index)].radioId">
                      <el-radio v-for="(option, $$index) in question.optionDTOList || []" :label="option.id" :key="index + '-' + $index + '-' + $$index">{{ option.content }}</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-if="question.type === 3">
                    <el-checkbox-group v-model="form.commentData[getQuestionIndex(index, $index)].checkedIds">
                      <el-checkbox v-for="(option, $$index) in question.optionDTOList || []" :label="option.id" :key="index + '-' + $index + '-' + $$index">{{ option.content }}</el-checkbox>
                    </el-checkbox-group>
                  </template>
                  <template v-if="question.type === 4">
                    <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="form.commentData[getQuestionIndex(index, $index)].replyDescription" :maxlength="500"> </el-input>
                  </template>
                </el-form-item>
              </template>
            </div>
          </div>
          <div class="section">
            <div class="title">欢迎提出意见或建议</div>
            <!-- <div class="desc">欢迎提出意见或建议</div> -->
            <div class="field-warp">
              <el-input type="textarea" :rows="3" v-model="form.suggestText" placeholder="面试过程有哪些印象深刻的地方，请告诉我们"></el-input>
            </div>
          </div>

          <div class="footer">
            <div></div>
            <div>
              <el-checkbox v-model="form.isHideName" :true-label="1" :false-label="0">匿名</el-checkbox>
              <el-button type="primary" size="small" :loading="loading" style="margin-left: 16px" @click="toSubmit">立即提交</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <message-box v-if="isSaved" :message="message" :code="code"></message-box>
  </div>
</template>

<script>
import vue from 'vue'
import { cloneDeep } from 'lodash'
import { getCommentFormInfo, saveCommentReplyContent } from '../../api/interview'
import MessageBox from './message-box.vue'
import { Form, FormItem, Rate, RadioGroup, Radio, CheckboxGroup, Checkbox, Input, Button, Loading } from 'element-ui'
vue.use(Form)
vue.use(FormItem)
vue.use(Rate)
vue.use(RadioGroup)
vue.use(Radio)
vue.use(CheckboxGroup)
vue.use(Checkbox)
vue.use(Input)
vue.use(Button)
vue.use(Loading)
export default {
  components: {
    MessageBox,
  },
  data() {
    return {
      loading: false,
      formInfo: {},
      moduleMap: {},
      isSaved: false,
      message: '',
      code: '',
      form: {
        commentReplyId: '',
        suggestText: '',
        isHideName: 1, // 0:不隐藏；1:隐藏
        commentData: [],
      },
    }
  },
  computed: {
    modules() {
      const formInfo = this.formInfo || {}
      const detail = formInfo.commentFormDetailDTO || {}
      const modules = detail.moduleDTOList || []
      return modules
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getCommentFormInfo()
    },
    getCommentFormInfo() {
      const commentReplyId = this.$route.query.commentReplyId
      this.loading = true
      getCommentFormInfo({ commentReplyId })
        .then(res => {
          this.code = res.code
          if (res.code === 0) {
            const data = res.data
            if (data) {
              this.form.commentReplyId = commentReplyId
              this.data2Form(data)
              this.formInfo = data
            }
          } else if (res.code === 9001) {
            this.isSaved = true
            this.message = res.msg
          } else {
            this.$notify({ type: 'danger', message: res.msg })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    data2Form(data = {}) {
      const commentData = []
      const detail = data.commentFormDetailDTO || {}
      const modules = detail.moduleDTOList || []
      const moduleMap = {}
      modules.forEach(module => {
        module.questionDTOList.forEach(question => {
          const key = module.id + '-' + question.id
          moduleMap[key] = { options: question.optionDTOList || [] }
          commentData.push({
            commentModuleId: module.id, //评价模块id
            formQuestionId: question.id, //评价问题id
            formQuestionType: question.type, //评价问题类型1打分题 2单选题 3多选题 4描述题
            formQuestionTitle: question.title, //问题
            radioId: '',
            checkedIds: [],
            replyScore: 0, //打分题目的分数
            replyDescription: '', //描述题目内容
          })
        })
      })
      this.moduleMap = moduleMap
      this.form.commentData = commentData
    },
    getQuestionIndex(index, $index) {
      let length = 0
      if (index > 0) {
        for (let i = 0; i < index; i++) {
          length += (this.modules[i].questionDTOList || []).length
        }
      }
      return length + $index
    },
    getRules(question) {
      if (question.type === 1) {
        return [
          {
            required: question.isMustAnswer === 1,
            validator(rule, value, callback) {
              if (value || question.isMustAnswer === 0) {
                callback()
              } else {
                callback(new Error('请打分'))
              }
            },
            trigger: 'change',
          },
        ]
      } else if (question.type === 2 || question.type === 3) {
        return [
          {
            required: question.isMustAnswer === 1,
            message: '请选择',
            trigger: 'blur',
          },
        ]
      } else {
        return [
          {
            required: question.isMustAnswer === 1,
            message: '请输入',
            trigger: 'blur',
          },
        ]
      }
    },
    getPropName(index, $index, question) {
      const length = this.getQuestionIndex(index, $index)
      const type = question.type
      let prorp = ''
      if (type === 1) {
        prorp = 'replyScore'
      } else if (type === 2) {
        prorp = 'radioId'
      } else if (type === 3) {
        prorp = 'checkedIds'
      } else {
        prorp = 'replyDescription'
      }
      return 'commentData.' + length + '.' + prorp
    },
    getFormData() {
      const form = cloneDeep(this.form)
      const commentData = form.commentData || []
      commentData.forEach(comment => {
        const key = comment.commentModuleId + '-' + comment.formQuestionId
        const options = (this.moduleMap[key] || {}).options || []

        if (comment.formQuestionType === 2) {
          const option = options.find(item => item.id === comment.radioId)
          comment.replyOptionId = comment.radioId
          comment.replyOptionTitle = (option || {}).content || ''
        } else if (comment.formQuestionType === 3) {
          const content = []
          options.forEach(item => {
            if (comment.checkedIds.includes(item.id)) {
              content.push(item.content)
            }
          })
          comment.replyOptionId = (comment.checkedIds || []).join(',')
          comment.replyOptionTitle = content.join(',')
        } else {
          comment.replyOptionId = ''
          comment.replyOptionTitle = ''
        }
        delete comment.checkedIds
        delete comment.radioId
      })
      return form
    },
    clear() {
      this.message = ''
      this.isSaved = false
      this.formInfo = {}
      this.moduleMap = {}
      this.form.commentData = []
    },
    toSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const form = this.getFormData()
          saveCommentReplyContent(form)
            .then(res => {
              if (res.code === 0) {
                this.message = res.msg
                this.isSaved = true
              } else {
                this.$notify({ type: 'danger', message: res.msg })
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
  },
  watch: {
    '$route.query.commentReplyId'() {
      this.clear()
      this.init()
    },
  },
  filters: {
    interviewTime(value = '') {
      const val = value || ''
      const split = val.split(' ')
      const date = split[0].split('-')
      const time = split[1] || ''
      return `${date[1]}月${date[2]}日${time}`
    },
  },
}
</script>

<style lang="scss" scoped>
.satisfaction {
  width: 100vw;
  height: calc(100vh - 64px);
  background: #f8f8fa;
  overflow-x: hidden;
  .satisfaction-head {
    width: 100vw;
    display: flex;
    align-items: center;
    padding-left: 10%;
    height: 64px;
    line-height: 64px;
    background: #1990ff;
    box-shadow: inset 0px -1px 0px 0px #eeeeee;
    backdrop-filter: blur(4px);
  }

  .satisfaction-body {
    margin: 24px auto 0;
    padding: 28px;
    max-width: 800px;
    width: 100vw;
    min-height: 100vh;
    background: #ffffff;

    .message {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
    }

    .split-line {
      margin-top: 20px;
      width: 32px;
      height: 4px;
      background: #1990ff;
    }

    .section {
      margin-top: 32px;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #111111;
      }
      .desc {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
      }

      .field-warp {
        margin-top: 8px;
        .form-rate {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #eeeeee;

          /deep/.el-form-item__label {
            min-width: 150px;
            width: 100%;
            padding: 0;
          }

          /deep/.el-form-item__content {
            display: flex;
            justify-content: end;
            flex-shrink: 0;
          }
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 26px;
      height: 40px;
    }
  }
}
</style>
