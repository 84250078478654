<template>
  <div class="message-warp">
    <div class="message-box">
      <div class="msg-content">
        <div>
          <i class="el-icon-success"></i>
        </div>
        <div>
          <div class="msg-title"><span v-if="code !== 9001">提交成功，</span>感谢您的评价</div>
          <div class="split-line"></div>
          <div class="message" v-html="msg"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '感谢提交，欢迎关注AI得贤招聘官！',
    },
    code: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    msg() {
      const reg = /<script[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
      const message = this.message || ''
      return message.replace(reg, message)
    },
  },
}
</script>

<style lang="scss" scoped>
.message-warp {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.message-box {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  max-height: 400px;
  .msg-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .el-icon-success {
      margin-bottom: 16px;
      font-size: 80px;
      color: #67c23a;
    }
    .msg-title {
      font-size: 26px;
      font-weight: 600;
      color: #333333;
      line-height: 40px;
      text-align: center;
    }
    .split-line {
      margin: 20px 0 16px;
      width: 100%;
      &::before {
        content: ' ';
        margin: 0 auto;
        display: block;
        width: 32px;
        height: 4px;
        background: #1990ff;
      }
    }

    .message {
      text-align: center;
    }
  }
}
</style>
